<template>
  <main class="w-screen h-screen lg:flex lg:justify-center lg:items-center overflow-y-auto" style="background-image: url('img/background.jpg')">
    <router-view/>
  </main>
</template>

<script>

export default {
  mounted() {
    const main = document.querySelector('main')
    const currentHour = new Date().getHours()

    if ( currentHour >= 6 && currentHour < 18 ) {
      main.style.backgroundImage = 'url("img/background.jpg")'
    } else  {
      main.style.backgroundImage = 'url("img/n-background.jpg")'
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

main {
  background-size: cover;
  background-repeat: no-repeat;
}

.noc-img-wrapper {
    margin: auto;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all ease 300ms;

    img {
        position: absolute;
        top: -100%;
        left: 0;
        right: 0;
        bottom: -100%;
        margin: auto;
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        display: block;
        -webkit-transition: all ease 300ms;
        transition: all ease 300ms;
    }
}
</style>
